import {computed} from "vue";
import {addYears, isMonday, isSunday, isTuesday, subDays} from "date-fns";

const today = new Date().setHours(0, 0, 0, 0);

const daysToSubtractForMinMoveDate = computed(() => {
	if (isSunday(today)) {
		return 3;
	} else if (isMonday(today) || isTuesday(today)) {
		return 4;
	} else {
		return 2;
	}
});

export const minMoveDate = subDays(today, daysToSubtractForMinMoveDate.value);
export const maxMoveDate = addYears(today, 2);
export const todayDate = new Date();
export const maxMeterReadingDate = subDays(today, 28);
